import axios from "axios";
import groupBy from "json-groupby";
import displayRes from "@/components/displayRes";
import { trim } from "jquery";
export default {
  name: "ricerche",
  components: {
    displayRes,
  },
  props: [],
  data() {
    return {
      hei: null,
      postPayload: {
        periodo: 0,
        autore: 0,
        strsrc: "",
        srccomp: "1",
        entire: "0",
        caps: "0",
        omogr: "1",
      },
      tmp_ric: ["entire", "caps", "omogr"],
      trovati: [],
      numTrovati: 0,
      periodoSel: "0",
      optPeriodi: [{ value: "0", text: "Tutti" }],
      autoreSel: "0",
      optAutori: [],
      strSrc: "",
      ricercaAttiva: false,
      doveCerco: "comp",
      opzRicerca: ["omogr"],
      ricercaOptions: [
        { text: "Termini esatti", value: "entire" },
        { text: "Rispetta M/m", value: "caps" },
        { text: "Omografi", value: "omogr" },
      ],
      fonti: [],
      omografi: [],
    };
  },
  computed: {
    numAutSel() {
      return this.optAutori.length - 1;
    },
  },
  mounted() {
    this.getWindowHeight();
    window.addEventListener("resize", this.getWindowHeight);
    console.log("ricerche -> mounted starts");
    this.modeDev = process.env.NODE_ENV === "development";
    if (this.$store.getters.isPeriodiLoaded) {
      console.log("Periodi pronti nello store, no AXIOS");
      this.setPeriodi();
    } else {
      this.$store.dispatch("loadPeriodi");
    }
    if (this.$store.getters.isAutoriLoaded) {
      console.log("Autori pronti nello store, no AXIOS");
      this.setAutori("0");
    } else {
      this.$store.dispatch("loadAutori");
    }
    if (this.$store.getters.isFontiLoaded) {
      console.log("Fonti pronte nello store, no AXIOS");
      this.setFonti();
    } else {
      this.$store.dispatch("loadFonti");
    }
    if (this.$store.getters.isOmografiLoaded) {
      console.log("Omografi pronti nello store, no AXIOS");
      this.omografi = this.$store.getters.getOmografi;
    } else {
      this.$store.dispatch("loadOmografi");
    }
    this.$store.subscribe((mutation) => {
      console.log("STORE: rilevata mutation [" + mutation.type + "]");
      if (mutation.type === "setPeriodi") {
        this.setPeriodi();
      }
      if (mutation.type === "setAutori") {
        this.setAutori("0");
      }
      if (mutation.type === "setFonti") {
        this.setFonti();
      }
      if (mutation.type === "setOmografi") {
        this.omografi = this.$store.getters.getOmografi;
      }
    });
  },
  methods: {
    getWindowHeight() {
      this.hei = String(document.documentElement.clientHeight - 290) + "px";
    },
    nuovaRicerca() {
      this.postPayload.strsrc = "";
      this.trovati = [];
      this.ricercaAttiva = false;
    },
    disRic() {
      var ret = true;
      if (trim(this.strSrc).length >= 3) {
        ret = false;
      }
      return ret;
    },
    esegui() {
      // ’n vece
      this.postPayload.strsrc = this.strSrc;
      axios
        .post("https://poesialirica.it/ws/qry-find-comp.php", this.postPayload)
        .then(
          (res) => (
            (this.ricercaAttiva = true),
            (this.numTrovati = res.data.length),
            (this.trovati = groupBy(res.data, [
              "cognome",
              "id_opera",
              "id_fonte",
              "id_sezione",
            ]))
          )
        )
        .catch((err) => console.log(err));
    },
    setPeriodi() {
      this.$store.getters.getPeriodi.forEach((x) =>
        this.optPeriodi.push({ value: x.id_periodo, text: x.descrizione })
      );
    },
    setAutori(idp) {
      var vin = [];
      this.optAutori = [{ value: "0", text: "Tutti" }];
      if (idp === "0") {
        vin = this.$store.getters.getAutori;
      } else {
        vin = this.$store.getters.getAutori.filter((x) => x.id_periodo === idp);
      }
      vin.forEach((x) =>
        this.optAutori.push({
          value: x.id_autore,
          text: x.nome + " " + x.cognome + " [" + x.data + "]",
          idPer: x.id_periodo,
        })
      );
    },
    setFonti() {
      this.fonti = groupBy(this.$store.getters.getFonti, ["id_fonte"]);
    },
    changePer(p) {
      this.postPayload.periodo = p;
      this.postPayload.autore = "0";
      this.autoreSel = 0;
      this.setAutori(p);
    },
    changeAut(p) {
      this.postPayload.autore = p;
    },
    changeDove(d) {
      if (d === "comp") {
        this.postPayload.srccomp = "1";
      } else {
        this.postPayload.srccomp = "0";
      }
    },
    changeOpzRic(opr) {
      this.tmp_ric.forEach((x) => {
        if (opr.includes(x)) {
          this.postPayload[x] = "1";
        } else {
          this.postPayload[x] = "0";
        }
      });
    },
    operaInfo(i) {
      return this.$store.getters.getOpere.find((x) => x.id_opera === i);
    },
    titoloComp(c) {
      if (c.titcomp === "null") {
        return c.testo.split("\r\n")[0];
      } else {
        return c.titcomp;
      }
    },
  },
};
